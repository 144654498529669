var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centere modal-lg" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("h2", [_vm._v("User Access Token")]),
            _c("button", {
              ref: "closeDactivatePopupButton",
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close"
              }
            })
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "row" }, [
              _vm.loadingToken
                ? _c(
                    "p",
                    {
                      staticStyle: { "text-align": "center", display: "block" }
                    },
                    [
                      _vm._v(
                        "\n                        loading.......\n                    "
                      )
                    ]
                  )
                : _vm._e(),
              !_vm.loadingToken && _vm.token
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "text-align": "justify",
                        display: "block",
                        "word-wrap": "break-word"
                      }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.token.access_token))])]
                  )
                : _vm._e()
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }