




































































































































































































































































































































































































































import Vue from 'vue'

import NonGeoUserPicklDetailsPopup from '@/components/common/NonGeoUserPicklDetailsPopup.vue'
import NonGeoPicklItem from '@/components/common/NonGeoPicklItem.vue'
import HollowCard from '@/components/common/HollowCard.vue'
import PicklItem from '@/components/common/PicklItem.vue'
import PaymentPopup from '@/components/SAdmin/PaymentPopup.vue'
import DeactivatePicklrPopup from '@/components/SAdmin/DeactivatePicklrPopup.vue'
import PicklDetails from '@/components/common/PicklDetails.vue'
import Component from 'vue-class-component'
import RemoveStaffPopup from '@/components/SAdmin/RemoveStaffPopup.vue'
import AddStaffPopup from '@/components/SAdmin/AddStaffPopup.vue'
import GenerateAccessToken from '@/components/SAdmin/GenerateAccessToken.vue'

@Component({
  components: {
    HollowCard,
    PicklItem,
    PaymentPopup,
    DeactivatePicklrPopup,
    PicklDetails,
    RemoveStaffPopup,
    AddStaffPopup,
    GenerateAccessToken,
    NonGeoPicklItem,
    NonGeoUserPicklDetailsPopup
  }
})
export default class PicklrDetails extends Vue {
  public picklStatus: any[] = []
  public picklr = { gender: null, id: '', status: '', 'zip_codes': [], staff_role: [], image: '', name: '', email: '', mobile_number: '' }
  public picklIndex: number = 0
  public limitPerPage: number = 100
  public picklOffset: number = 0
  public paymentOffset: number = 0
  public pickls: any[] = []
  public payments: any[] = []
  public showPointsBox: boolean = false
  public totalPaidCredits: number = 0
  public invalidPoints: boolean = false
  public bonusPoints: any = ''
  public activePickl = {}
  public loadingPickls: boolean = true
  public hasMorePickls: boolean = false
  public hasMorePayments: boolean = false
  public totalBonusPointsGiven: number = 0
  public BonuslimitPerPage: number = 100
  public BonusOffset = 0
  public hasMoreBonus: boolean = false
  public bonusPointsList: any[] = []
  public loadingBonus = true
  public addBonusLoading: boolean = false
  public userRole: any = 'staff'
  public token: any = {}
  public loadingToken: boolean = true

  // home pickl variable
  public homePickls: any[] = []
  public homePicklIndex: number = 0
  public homeLimitPerPage: number = 100
  public homePicklOffset: number = 0
  public homePicklStatus: any[] = []
  public hasMoreHomePickls: boolean = false
  public loadingHomePickls: boolean = true
  selectedUserPickl: any [] = []
  public loadingUserPicklDetails: boolean = true
  public userPicklId: number = 0
  public brand: boolean = false

  // credits varaiable
  public creditsList: any[] = []
  public loadingCreditsList: boolean = true
  public creditsPageLimit: number = 50
  public creditsOffset: number = 0
  public hasMoreCredits: boolean = false

  mounted () {
    let userData = this.$store.state.userData
    this.getUserCreditsHistory()
    this.userRole = userData.role
    this.getUser()
    this.$store.dispatch('getAppSettingsKeyValuePair')
    this.getUserPicklCount()
    this.getUserHomePicklCount()
    this.getUserPaymentHistory()
    this.getUserBonusHistory()
    this.getUserHomePickls()
  }
  getUserCreditsHistory () {
    console.log('here we are')
    this.loadingCreditsList = true
    let params = { 'limit': this.creditsPageLimit, 'offset': this.creditsOffset }
    this.$http
      .get('users/' + this.$route.params.picklrId + '/credit/history', { params: params })
      .then((response: any) => {
        this.totalBonusPointsGiven = response.body.sum
        if (params.limit === this.creditsPageLimit && params.offset === this.creditsOffset) {
          this.creditsOffset += this.creditsPageLimit
          console.log(response.body.results)
          this.creditsList.push(...response.body.results)
          console.log(this.creditsList)
          if (this.creditsList.length === response.body.count) {
            this.hasMoreCredits = false
          } else {
            this.hasMoreCredits = true
          }
        }
        this.loadingCreditsList = false
      }, response => {

      })
  }
  pushToPicklDetailsPage (picklId: any) {
    this.$router.push('/sadmin/pickls/' + picklId)
  }
  getUser () {
    this.$http
      .get('picklrs/' + this.$route.params.picklrId)
      .then((response: any) => {
        this.picklr = response.body.data
      }, response => {
      })
  }

  generateUserAccessToken () {
    this.$http
      .post('users/' + this.$route.params.picklrId + '/generate-token')
      .then((response: any) => {
        this.token = response.body
        this.loadingToken = false
      }, response => {
      })
  }

  getUserPicklCount () {
    this.loadingPickls = true
    this.$http
      .get('picklrs/' + this.$route.params.picklrId + '/picklsCount')
      .then((response: any) => {
        this.picklStatus = response.body.data
        this.loadingPickls = false
        if (this.picklStatus.length > 0) {
          this.getUserPickls()
        }
      }, response => {
        this.loadingPickls = false
      })
  }
  getUserPicklInfo (userPicklId: number) {
    this.loadingUserPicklDetails = true
    this.$store
      .dispatch('getNonGeoUserPicklDetails', { userPicklId: userPicklId })
      .then(response => {
        this.selectedUserPickl = response.response.body
        this.loadingUserPicklDetails = false
      })
  }
  getUserHomePicklCount () {
    this.loadingPickls = true
    this.$http
      .get('picklrs/' + this.$route.params.picklrId + '/homePicklsCount')
      .then((response: any) => {
        this.homePicklStatus = response.body.data
        this.loadingPickls = false
        if (this.homePicklStatus.length > 0) {
          this.getUserHomePickls()
        }
      }, response => {
        this.loadingPickls = false
      })
  }
  getUserPickls () {
    this.loadingPickls = true
    console.log(this.picklStatus[this.picklIndex])
    let sort = Vue.common.statusSortOrder[this.picklStatus[this.picklIndex].status]
    let params = { 'limit': this.limitPerPage, 'offset': this.picklOffset, 'status': this.picklStatus[this.picklIndex].status, 'sort': sort }
    this.$http
      .get('picklrs/' + this.$route.params.picklrId + '/pickls', { params: params })
      .then((response: any) => {
        if (params.limit === this.limitPerPage && params.offset === this.picklOffset && params.status === this.picklStatus[this.picklIndex].status) {
          this.picklOffset += this.limitPerPage
          this.pickls.push(...response.body.data)
          if (this.pickls.length === response.body.count) {
            this.hasMorePickls = false
          } else {
            this.hasMorePickls = true
          }
          this.loadingPickls = false
        }
      }, response => {
        this.loadingPickls = false
      })
  }
  getUserHomePickls () {
    this.loadingPickls = true
    let sort = Vue.common.statusSortOrder[this.homePicklStatus[this.homePicklIndex].status]
    let params = { 'limit': this.homeLimitPerPage, 'offset': this.homePicklOffset, 'status': this.homePicklStatus[this.homePicklIndex].status, 'sort': sort }
    this.$http
      .get('picklrs/' + this.$route.params.picklrId + '/home-pickls', { params: params })
      .then((response: any) => {
        if (params.limit === this.homeLimitPerPage && params.offset === this.homePicklOffset && params.status === this.homePicklStatus[this.homePicklIndex].status) {
          this.homePicklOffset += this.homeLimitPerPage
          this.homePickls.push(...response.body.data)
          if (this.homePickls.length === response.body.count) {
            this.hasMoreHomePickls = false
          } else {
            this.hasMoreHomePickls = true
          }
          this.loadingHomePickls = false
        }
      }, response => {
        this.loadingHomePickls = false
      })
  }
  updatePicklsList (index: number) {
    this.pickls = []
    this.picklIndex = index
    this.picklOffset = 0
    this.hasMorePickls = false
    this.getUserPickls()
  }
  updateHomePicklsList (index: number) {
    this.homePickls = []
    this.homePicklIndex = index
    this.homePicklOffset = 0
    this.hasMoreHomePickls = false
    this.getUserHomePickls()
  }
  updatePaymentHistory () {
    this.payments = []
    this.paymentOffset = 0
    this.hasMorePayments = false
    this.getUser()
    this.getUserPaymentHistory()
  }
  getUserPaymentHistory () {
    let params = { 'limit': this.limitPerPage, 'offset': this.paymentOffset }
    this.$http
      .get('users/' + this.$route.params.picklrId + '/payments', { params: params })
      .then((response: any) => {
        this.totalPaidCredits = response.body.sum
        if (params.limit === this.limitPerPage && params.offset === this.paymentOffset) {
          this.paymentOffset += this.limitPerPage
          this.payments.push(...response.body.data)
          if (this.payments.length === response.body.count) {
            this.hasMorePayments = false
          } else {
            this.hasMorePayments = true
          }
        }
      }, response => {

      })
  }
  getUserBonusHistory () {
    this.loadingBonus = true
    let params = { 'limit': this.BonuslimitPerPage, 'offset': this.BonusOffset }
    this.$http
      .get('users/' + this.$route.params.picklrId + '/bonus_points', { params: params })
      .then((response: any) => {
        this.totalBonusPointsGiven = response.body.sum
        if (params.limit === this.BonuslimitPerPage && params.offset === this.BonusOffset) {
          this.BonusOffset += this.BonuslimitPerPage
          this.bonusPointsList.push(...response.body.data)
          if (this.bonusPointsList.length === response.body.count) {
            this.hasMoreBonus = false
          } else {
            this.hasMoreBonus = true
          }
        }
        this.loadingBonus = false
      }, response => {

      })
  }
  getStatusAction () {
    if (this.picklr.status === 'ACTIVE') {
      return 'INACTIVE'
    } else if (this.picklr.status === 'INACTIVE') {
      return 'ACTIVE'
    }
  }
  addBonusPoints () {
    if (isNaN(this.bonusPoints) || this.bonusPoints < 1) {
      this.invalidPoints = true
    } else {
      this.addBonusLoading = true
      this.invalidPoints = false
      let data = { credits: this.bonusPoints, notes: 'NICE PICKL BONUS!  😊' }
      this.$http
        .put('users/' + this.$route.params.picklrId + '/credits', data)
        .then((response: any) => {
          this.addBonusLoading = false
          this.$notify({ text: `${this.bonusPoints} points added to picklr`, type: 'success' })
          this.showPointsBox = false
          this.bonusPoints = ''
          this.getUser()
          this.bonusPointsList = []
          this.BonusOffset = 0
          this.getUserBonusHistory()
        }, response => {
          this.$notify({ text: 'Failed to add bonus points to picklr', type: 'error' })
        })
    }
  }
  updatePicklsCount () {
    this.pickls = []
    this.picklOffset = 0
    this.hasMorePickls = false
    this.getUserPicklCount()
  }
}
