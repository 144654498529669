





















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class PaymentPopup extends Vue {
    @Prop() popupId!: string;
    @Prop() creditAmount!: number;
    @Prop() userId!: number;
    submittingForm: boolean = false
    amount:any = ''
    chequeNumber:string = ''
    busy: boolean = false

    $refs!: {
      closePaymentPopupButton: HTMLFormElement
    }
    addPayment () {
      this.$validator.validateAll('payment').then((result) => {
        if (result) {
          if (this.amount > this.creditAmount) {
            this.$notify({ type: 'error', text: "Amount can't be more than current credit amount of picklr" })
            return false
          }
          let data = {
            user_id: this.userId,
            credits_paid: this.amount,
            cheque_number: this.chequeNumber
          }
          this.busy = true
          this.$http
            .post('user_payment_history', data)
            .then((response:any) => {
              this.amount = ''
              this.chequeNumber = ''
              this.$nextTick()
                .then(() => {
                  this.$validator.errors.clear('payment')
                })
              this.$refs.closePaymentPopupButton.click()
              this.$emit('sendPaymentUpdate', data)
              this.busy = false
            }, response => {
              this.busy = false
            })
        }
      })
    }
}
