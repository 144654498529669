








































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class DeactivatePicklrPopup extends Vue {
  public submittingForm: boolean = false
  public staff = { password: '' }
  @Prop() popupId!: string
  @Prop() userId!: number
  $refs!: {
    closeDactivatePopupButton: HTMLFormElement
  }
  addStaff () {
    this.$validator.validateAll('add-staff').then((result) => {
      if (result) {
        this.submittingForm = true
        this.$http
          .post('users/' + this.userId + '/add-to-staff', { 'password': this.staff.password })
          .then((response: any) => {
            this.$refs.closeDactivatePopupButton.click()
            this.$emit('sendActionUpdate')
            this.submittingForm = false
          }, response => {
          })
      }
    })
  }
}
