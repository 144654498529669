
























import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class DeactivatePicklrPopup extends Vue {
    @Prop() popupId!: string
    @Prop() token!: object
    @Prop() loadingToken!: boolean
    $refs!: {
      closeDactivatePopupButton: HTMLFormElement
    }
}
