

























import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class DeactivatePicklrPopup extends Vue {
    @Prop() popupId!: string
    @Prop() userId!: number
    $refs!: {
      closeDactivatePopupButton: HTMLFormElement
    }
    removeFromStaff () {
      this.$http
        .post('users/' + this.userId + '/remove-from-staff')
        .then((response:any) => {
          this.$refs.closeDactivatePopupButton.click()
          this.$emit('sendActionUpdate')
        }, response => {
        })
    }
}
