var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("h2", [_vm._v("Send Payment")]),
            _c("button", {
              ref: "closePaymentPopupButton",
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close"
              }
            })
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                attrs: { autocomplete: "off", "data-vv-scope": "payment" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.addPayment($event)
                  }
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.amount,
                      expression: "amount"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|numeric",
                      expression: "'required|numeric'"
                    }
                  ],
                  class: {
                    input: true,
                    "is-danger": _vm.errors.has("payment.amount")
                  },
                  attrs: {
                    type: "text",
                    placeholder: "Amount",
                    name: "amount"
                  },
                  domProps: { value: _vm.amount },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.amount = $event.target.value
                    }
                  }
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.chequeNumber,
                      expression: "chequeNumber"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  class: {
                    input: true,
                    "is-danger": _vm.errors.has("payment.chequeNumber")
                  },
                  attrs: {
                    type: "text",
                    placeholder: "Cheque No.",
                    name: "chequeNumber"
                  },
                  domProps: { value: _vm.chequeNumber },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.chequeNumber = $event.target.value
                    }
                  }
                }),
                _c("input", {
                  staticClass: "btn-lg-green",
                  attrs: {
                    type: "submit",
                    value: "SEND PAYMENT",
                    disabled: _vm.busy
                  }
                })
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }