var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "green-border-box white-bg" },
    [
      _c("div", { staticClass: "profile-head" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-6 col-md-5 col-lg-6 nopadd" },
          [
            _c("div", { staticClass: "green-border-box picklr-photo" }, [
              _c("img", {
                directives: [{ name: "square-img", rawName: "v-square-img" }],
                attrs: { src: _vm.picklr.image + "?w=150&q=50" },
                on: {
                  error: function($event) {
                    _vm.picklr.image = "/img/pickl-logo.png"
                  }
                }
              })
            ]),
            _c("div", { staticClass: "afterImage" }, [
              _c("div", { staticClass: "vertical-center-transform" }, [
                _c("div", { staticClass: "profile-name" }, [
                  _vm._v(_vm._s(_vm.picklr.name)),
                  _c("small", { staticClass: "staff-tag" }, [
                    _vm._v(
                      _vm._s(
                        _vm.picklr.staff_role.length == 1
                          ? " (Staff Member)"
                          : ""
                      )
                    )
                  ])
                ]),
                _c(
                  "a",
                  {
                    staticClass: "phone-number",
                    attrs: { href: "tel:" + _vm.picklr.mobile_number }
                  },
                  [_vm._v(_vm._s(_vm._f("phone")(_vm.picklr.mobile_number)))]
                ),
                _c("br")
              ])
            ])
          ]
        ),
        _c("div", { staticClass: "col-xs-12 mobile-afterImage" }, [
          _c(
            "a",
            {
              staticClass: "email",
              attrs: { href: "email:" + _vm.picklr.email }
            },
            [_vm._v(_vm._s(_vm.picklr.email))]
          ),
          _c("br"),
          _vm.picklr.address
            ? _c("a", { staticClass: "address" }, [
                _vm._v(_vm._s(_vm.picklr.address))
              ])
            : _vm._e(),
          _c("a", [_vm._v("Gender: " + _vm._s(_vm.picklr.gender))])
        ]),
        _vm.userRole == "super-admin"
          ? _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-6 col-md-7 col-lg-6 action-buttons nopadd"
              },
              [
                _vm.picklr.staff_role.length == 1
                  ? _c(
                      "button",
                      {
                        staticClass: "btn-negative",
                        attrs: {
                          type: "button",
                          "data-toggle": "modal",
                          "data-target": "#removestaffpopup"
                        }
                      },
                      [_vm._v("REMOVE FROM STAFF")]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn-negative",
                        attrs: {
                          type: "button",
                          "data-toggle": "modal",
                          "data-target": "#addstaffpopup"
                        }
                      },
                      [_vm._v("ADD TO STAFF")]
                    ),
                _vm.showPointsBox
                  ? _c("span", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.bonusPoints,
                            expression: "bonusPoints"
                          }
                        ],
                        staticClass: "green-border-box points-box",
                        class: { input: true, "is-danger": _vm.invalidPoints },
                        attrs: { type: "text", placeholder: "Add points" },
                        domProps: { value: _vm.bonusPoints },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.bonusPoints = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "btn-circ",
                          on: {
                            click: function($event) {
                              _vm.showPointsBox = false
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: { src: "/img/Picklr/addPoints_no.png" }
                          })
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn-circ",
                          attrs: { disabled: _vm.addBonusLoading },
                          on: { click: _vm.addBonusPoints }
                        },
                        [
                          _c("img", {
                            attrs: { src: "/img/Picklr/addPoints_yes.png" }
                          })
                        ]
                      )
                    ])
                  : _c(
                      "button",
                      {
                        staticClass: "btn-positive",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.showPointsBox = true
                          }
                        }
                      },
                      [_vm._v("BONUS POINTS")]
                    ),
                _vm.picklr.status == "ACTIVE"
                  ? _c(
                      "button",
                      {
                        staticClass: "btn-negative",
                        attrs: {
                          type: "button",
                          "data-toggle": "modal",
                          "data-target": "#deactivatePicklrPopup"
                        }
                      },
                      [_vm._v("DEACTIVATE")]
                    )
                  : _vm._e(),
                _vm.picklr.status == "INACTIVE"
                  ? _c(
                      "button",
                      {
                        staticClass: "btn-positive",
                        attrs: {
                          type: "button",
                          "data-toggle": "modal",
                          "data-target": "#deactivatePicklrPopup"
                        }
                      },
                      [_vm._v("ACTIVATE")]
                    )
                  : _vm._e(),
                _vm.picklr.status == "ACTIVE"
                  ? _c(
                      "button",
                      {
                        staticClass: "btn-positive",
                        attrs: {
                          type: "button",
                          "data-toggle": "modal",
                          "data-target": "#generatedAccessToken"
                        },
                        on: { click: _vm.generateUserAccessToken }
                      },
                      [_vm._v("GENERATE TOKEN")]
                    )
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "col-xs-12 desktop-afterImage" }, [
          _c(
            "a",
            {
              staticClass: "email",
              attrs: { href: "email:" + _vm.picklr.email }
            },
            [_vm._v(_vm._s(_vm.picklr.email))]
          ),
          _c("br"),
          _vm.picklr.address
            ? _c("a", { staticClass: "address" }, [
                _vm._v(_vm._s(_vm.picklr.address))
              ])
            : _vm._e(),
          _vm.picklr.address ? _c("br") : _vm._e(),
          _c("a", { staticClass: "address" }, [
            _vm.picklr.zip_codes.length > 0
              ? _c(
                  "span",
                  _vm._l(_vm.picklr.zip_codes, function(zip_code, index) {
                    return _c("span", { key: zip_code.id }, [
                      _vm._v(_vm._s(zip_code.formatted_address)),
                      index < _vm.picklr.zip_codes.length - 1
                        ? _c("span", [_vm._v(" | ")])
                        : _vm._e()
                    ])
                  }),
                  0
                )
              : _vm._e()
          ]),
          _c("br"),
          _vm.picklr.gender !== null
            ? _c(
                "a",
                {
                  staticStyle: {
                    color: "rgba(0, 0, 0, 0.8)",
                    "font-size": "15px",
                    "font-weight": "bold"
                  }
                },
                [_vm._v("Gender: " + _vm._s(_vm.picklr.gender))]
              )
            : _vm._e(),
          _vm.picklr.gender != null ? _c("br") : _vm._e(),
          _vm.picklr.date_of_birth !== null
            ? _c(
                "a",
                {
                  staticStyle: {
                    color: "rgba(0, 0, 0, 0.8)",
                    "font-size": "15px",
                    "font-weight": "bold"
                  }
                },
                [
                  _vm._v(
                    "Date Of Birth: " +
                      _vm._s(_vm._f("fullDate")(_vm.picklr.date_of_birth))
                  )
                ]
              )
            : _vm._e(),
          _vm.picklr.date_of_birth != null ? _c("br") : _vm._e(),
          _vm.picklr.children !== null
            ? _c(
                "a",
                {
                  staticStyle: {
                    color: "rgba(0, 0, 0, 0.8)",
                    "font-size": "15px",
                    "font-weight": "bold"
                  }
                },
                [_vm._v("Children: " + _vm._s(_vm.picklr.children))]
              )
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "green-border-box" }, [
        _vm._m(0),
        _c("br"),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _vm._l(_vm.picklr.stateWisePicklCount, function(state) {
              return _c(
                "span",
                {
                  key: state.state,
                  staticStyle: { "font-weight": "bold", padding: "5px" }
                },
                [
                  _vm._v(
                    _vm._s(state.state) +
                      "(" +
                      _vm._s(state.locations_count) +
                      ") |"
                  )
                ]
              )
            }),
            _c("br"),
            _c("br")
          ],
          2
        )
      ]),
      _c("div", { staticClass: "clearfix" }),
      _c("div", { staticClass: "posRel" }, [
        _c("div", { staticClass: "col-xs-4 part" }, [
          _c("h4", [_vm._v(_vm._s(_vm.picklr.completed_pickls_count))]),
          _vm._v("Completed\n    ")
        ]),
        _c("div", { staticClass: "col-xs-4 part part-pay" }, [
          _c(
            "h4",
            {
              class: {
                "due-alert":
                  _vm.picklr.current_credits >=
                  _vm.$store.state.settings.credit_payment_threshold
              }
            },
            [_vm._v("$" + _vm._s(_vm.picklr.current_credits))]
          ),
          _vm.userRole == "super-admin"
            ? _c(
                "button",
                {
                  staticClass: "btn-negative",
                  attrs: {
                    "data-toggle": "modal",
                    "data-target": "#paymentPopup",
                    disabled: !(
                      _vm.picklr.current_credits + 1 >
                      _vm.$store.state.settings.credit_payment_threshold
                    )
                  }
                },
                [_vm._v("SEND PAYMENT")]
              )
            : _c("span", { staticClass: "btn-negative" }, [
                _vm._v("To Be Paid")
              ])
        ]),
        _c("div", { staticClass: "col-xs-4 part" }, [
          _vm.picklr.credits_paid_sum
            ? _c("h4", [_vm._v("$" + _vm._s(_vm.picklr.credits_paid_sum))])
            : _c("h4", [_vm._v("$0")]),
          _vm._v("Paid\n    ")
        ])
      ]),
      _c("div", { staticClass: "clearfix" }),
      _c("div", { staticClass: "in-padd" }, [
        _vm._m(1),
        _c("div", { staticClass: "tab-content" }, [
          _c(
            "div",
            {
              staticClass: "tab-pane fade in active",
              attrs: { role: "tabpanel", id: "pickl" }
            },
            [
              _c(
                "div",
                { staticClass: "row mt20" },
                _vm._l(_vm.picklStatus, function(history, index) {
                  return _c(
                    "div",
                    {
                      key: history.status,
                      staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-3"
                    },
                    [
                      _c("HollowCard", {
                        attrs: {
                          active: _vm.picklIndex === index,
                          count: history.count,
                          title: _vm.$common.getStatusTitle(history.status),
                          color:
                            _vm.$common.cardColors[
                              _vm.picklStatus[index].status
                            ]
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.updatePicklsList(index)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              ),
              _vm.picklStatus[_vm.picklIndex]
                ? _c("h3", { staticClass: "sub-heading" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$common.getStatusTitle(
                          _vm.picklStatus[_vm.picklIndex].status
                        )
                      )
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.pickls, function(pickl) {
                  return _c(
                    "div",
                    { key: pickl.id, staticClass: "col-sm-12 col-md-6" },
                    [
                      _c("PicklItem", {
                        attrs: { pickl: pickl },
                        nativeOn: {
                          click: function($event) {
                            return _vm.pushToPicklDetailsPage(pickl.id)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              ),
              _vm.hasMorePickls && !_vm.loadingPickls
                ? _c("div", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn-positive",
                        on: { click: _vm.getUserPickls }
                      },
                      [_vm._v("Load more")]
                    )
                  ])
                : _vm._e(),
              !_vm.loadingPickls && (!_vm.pickls || _vm.pickls.length == 0)
                ? _c("h3", { staticClass: "text-center mt20" }, [
                    _vm._v("There aren't any Pickls here!")
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "div",
            {
              staticClass: "tab-pane fade in",
              attrs: { role: "tabpanel", id: "home-pickl" }
            },
            [
              _c(
                "div",
                { staticClass: "row mt20" },
                _vm._l(_vm.homePicklStatus, function(history, index) {
                  return _c(
                    "div",
                    {
                      key: history.status,
                      staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-3"
                    },
                    [
                      _c("HollowCard", {
                        attrs: {
                          active: _vm.homePicklIndex === index,
                          count: history.count,
                          title: _vm.$common.getStatusTitle(history.status),
                          color:
                            _vm.$common.cardColors[
                              _vm.homePicklStatus[index].status
                            ]
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.updateHomePicklsList(index)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              ),
              _vm.picklStatus[_vm.picklIndex]
                ? _c("h3", { staticClass: "sub-heading" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$common.getStatusTitle(
                          _vm.picklStatus[_vm.picklIndex].status
                        )
                      )
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.homePickls, function(pickl) {
                  return _c(
                    "div",
                    { key: pickl.id, staticClass: "col-sm-12 col-md-6" },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "#",
                            "data-target": "#user-pickl-details-popup",
                            "data-toggle": "modal"
                          },
                          on: {
                            click: function($event) {
                              return _vm.getUserPicklInfo(pickl.user_pickl.id)
                            }
                          }
                        },
                        [_c("NonGeoPicklItem", { attrs: { pickl: pickl } })],
                        1
                      )
                    ]
                  )
                }),
                0
              ),
              _vm.hasMorePickls && !_vm.loadingPickls
                ? _c("div", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn-positive",
                        on: { click: _vm.getUserPickls }
                      },
                      [_vm._v("Load more")]
                    )
                  ])
                : _vm._e(),
              !_vm.loadingPickls && (!_vm.pickls || _vm.pickls.length == 0)
                ? _c("h3", { staticClass: "text-center mt20" }, [
                    _vm._v("There aren't any Pickls here!")
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "div",
            {
              staticClass: "tab-pane fade posRel",
              attrs: { role: "tabpanel", id: "payment" }
            },
            [
              _vm.totalPaidCredits > 0
                ? _c("span", { staticClass: "total-red" }, [
                    _vm._v("TOTAL PAID: $" + _vm._s(_vm.totalPaidCredits))
                  ])
                : _vm._e(),
              _vm.totalPaidCredits > 0
                ? _c("h4", { staticClass: "month-label" })
                : _vm._e(),
              _c("br"),
              _c(
                "div",
                { staticClass: "transactions" },
                _vm._l(_vm.payments, function(payment) {
                  return _c(
                    "div",
                    { key: payment.id, staticClass: "transaction" },
                    [
                      _c("img", {
                        staticClass: "vertical-center-transform timeline-icon",
                        attrs: { src: "/img/Picklr/paymentTimeline_icon.png" }
                      }),
                      _c("div", { staticClass: "green-border-box posRel" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-4 transaction-left"
                          },
                          [
                            _c("img", {
                              staticClass: "payment-icon",
                              attrs: { src: "/img/Picklr/payment_icon.png" }
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vertical-center-transform cheque-details"
                              },
                              [
                                _vm._v("Cheque #\n                  "),
                                _c("br"),
                                _c("strong", [
                                  _vm._v(_vm._s(payment.cheque_number))
                                ])
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-6 date-time" },
                          [
                            _c("span", { staticClass: "mr20" }, [
                              _c("img", {
                                attrs: { src: "/img/Picklr/date_icon.png" }
                              }),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(payment.date) +
                                  "\n                "
                              )
                            ]),
                            _c("span", [
                              _c("img", {
                                attrs: { src: "/img/Picklr/time_icon.png" }
                              }),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(payment.time) +
                                  "\n                "
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-2 nopadd amount" },
                          [_vm._v("$" + _vm._s(payment.credits_paid))]
                        )
                      ])
                    ]
                  )
                }),
                0
              ),
              _vm.hasMorePayments
                ? _c("div", { staticClass: "text-center mt20" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn-positive",
                        on: { click: _vm.getUserPaymentHistory }
                      },
                      [_vm._v("Load more")]
                    )
                  ])
                : _vm._e(),
              !_vm.payments || _vm.payments.length == 0
                ? _c("h3", { staticClass: "text-center mt20" }, [
                    _vm._v(
                      "There hasn't been any transactions for this Picklr yet."
                    )
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "div",
            {
              staticClass: "tab-pane fade posRel",
              attrs: { role: "tabpanel", id: "credits" }
            },
            [
              _c("br"),
              _vm.creditsList.length > 0
                ? _c(
                    "div",
                    { staticClass: "transactions" },
                    _vm._l(_vm.creditsList, function(credit) {
                      return _c(
                        "div",
                        { key: credit.id, staticClass: "transaction" },
                        [
                          _c("img", {
                            staticClass:
                              "vertical-center-transform timeline-icon",
                            attrs: {
                              src: "/img/Picklr/paymentTimeline_icon.png"
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "green-border-box posRel" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-12 col-sm-4 transaction-left"
                                },
                                [
                                  _c("img", {
                                    staticClass: "payment-icon",
                                    attrs: {
                                      src: "/img/Picklr/payment_icon.png"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vertical-center-transform cheque-details"
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            credit.notes
                                              ? credit.notes
                                              : "PICKL APPROVED"
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-xs-12 col-sm-6 date-time" },
                                [
                                  _c("span", { staticClass: "mr20" }, [
                                    _c("img", {
                                      attrs: {
                                        src: "/img/Picklr/date_icon.png"
                                      }
                                    }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm._f("humanReadableDate")(
                                            credit.created_at
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-12 col-sm-2 nopadd amount"
                                },
                                [_vm._v("$" + _vm._s(credit.credits))]
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.hasMoreCredits
                ? _c("div", { staticClass: "text-center mt20" }, [
                    !_vm.loadingCreditsList
                      ? _c(
                          "button",
                          {
                            staticClass: "btn-positive",
                            on: { click: _vm.getUserCreditsHistory }
                          },
                          [_vm._v("Load more")]
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              (!_vm.bonusPointsList || _vm.bonusPointsList.length == 0) &&
              !_vm.loadingBonus
                ? _c("h3", { staticClass: "text-center mt20" }, [
                    _vm._v("No Credits available!")
                  ])
                : _vm._e(),
              _vm.loadingBonus
                ? _c("h3", { staticClass: "text-center mt20" }, [
                    _vm._v("Loading Bonus Points...")
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "div",
            {
              staticClass: "tab-pane fade posRel",
              attrs: { role: "tabpanel", id: "bonus" }
            },
            [
              _vm.totalBonusPointsGiven > 0
                ? _c("span", { staticClass: "total-red" }, [
                    _vm._v("TOTAL BONUS: $" + _vm._s(_vm.totalBonusPointsGiven))
                  ])
                : _vm._e(),
              _vm.totalBonusPointsGiven > 0
                ? _c("h4", { staticClass: "month-label" })
                : _vm._e(),
              _c("br"),
              _c(
                "div",
                { staticClass: "transactions" },
                _vm._l(_vm.bonusPointsList, function(point) {
                  return _c(
                    "div",
                    { key: point.id, staticClass: "transaction" },
                    [
                      _c("img", {
                        staticClass: "vertical-center-transform timeline-icon",
                        attrs: { src: "/img/Picklr/paymentTimeline_icon.png" }
                      }),
                      _c("div", { staticClass: "green-border-box posRel" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col-xs-12 col-sm-4 transaction-left"
                          },
                          [
                            _c("img", {
                              staticClass: "payment-icon",
                              attrs: { src: "/img/Picklr/payment_icon.png" }
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vertical-center-transform cheque-details"
                              },
                              [_c("strong", [_vm._v(_vm._s(point.notes))])]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-6 date-time" },
                          [
                            _c("span", { staticClass: "mr20" }, [
                              _c("img", {
                                attrs: { src: "/img/Picklr/date_icon.png" }
                              }),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(point.date) +
                                  "\n                "
                              )
                            ]),
                            _c("span", [
                              _c("img", {
                                attrs: { src: "/img/Picklr/time_icon.png" }
                              }),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(point.time) +
                                  "\n                "
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-2 nopadd amount" },
                          [_vm._v("$" + _vm._s(point.credits))]
                        )
                      ])
                    ]
                  )
                }),
                0
              ),
              _vm.hasMoreBonus
                ? _c("div", { staticClass: "text-center mt20" }, [
                    !_vm.loadingBonus
                      ? _c(
                          "button",
                          {
                            staticClass: "btn-positive",
                            on: { click: _vm.getUserBonusHistory }
                          },
                          [_vm._v("Load more")]
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              (!_vm.bonusPointsList || _vm.bonusPointsList.length == 0) &&
              !_vm.loadingBonus
                ? _c("h3", { staticClass: "text-center mt20" }, [
                    _vm._v("No bonus points given so yet!")
                  ])
                : _vm._e(),
              _vm.loadingBonus
                ? _c("h3", { staticClass: "text-center mt20" }, [
                    _vm._v("Loading Bonus Points...")
                  ])
                : _vm._e()
            ]
          )
        ])
      ]),
      _c("NonGeoUserPicklDetailsPopup", {
        attrs: {
          userPicklId: _vm.userPicklId,
          popupId: "user-pickl-details-popup",
          brand: _vm.brand,
          nonGeoPicklDetails: _vm.selectedUserPickl,
          loadingUserPicklDetails: _vm.loadingUserPicklDetails
        },
        on: { statusUpdated: _vm.updateHomePicklsList }
      }),
      _c("PaymentPopup", {
        attrs: {
          creditAmount: _vm.picklr.current_credits,
          popupId: "paymentPopup",
          userId: _vm.picklr.id
        },
        on: { sendPaymentUpdate: _vm.updatePaymentHistory }
      }),
      _c("DeactivatePicklrPopup", {
        attrs: {
          popupId: "deactivatePicklrPopup",
          action: _vm.getStatusAction(),
          userId: _vm.picklr.id
        },
        on: { sendActionUpdate: _vm.getUser }
      }),
      _c("GenerateAccessToken", {
        attrs: {
          popupId: "generatedAccessToken",
          token: _vm.token,
          loadingToken: _vm.loadingToken
        }
      }),
      _c("RemoveStaffPopup", {
        attrs: {
          popupId: "removestaffpopup",
          action: _vm.getStatusAction(),
          userId: _vm.picklr.id
        },
        on: { sendActionUpdate: _vm.getUser }
      }),
      _c("AddStaffPopup", {
        attrs: {
          popupId: "addstaffpopup",
          action: _vm.getStatusAction(),
          userId: _vm.picklr.id
        },
        on: { sendActionUpdate: _vm.getUser }
      }),
      _c("PicklDetails", {
        attrs: { popupId: "pickl-details-popup", pickl: _vm.activePickl },
        on: { updatePicklsCount: _vm.updatePicklsCount }
      }),
      _c("NonGeoUserPicklDetailsPopup", {
        attrs: {
          userPicklId: _vm.userPicklId,
          popupId: "user-pickl-details-popup",
          brand: _vm.brand,
          nonGeoPicklDetails: _vm.selectedUserPickl,
          loadingUserPicklDetails: _vm.loadingUserPicklDetails
        },
        on: { statusUpdated: _vm.updateHomePicklsList }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h3",
      { staticStyle: { "margin-left": "10px", "margin-right": "10px" } },
      [
        _c("span", { staticStyle: { float: "left" } }, [
          _c("strong", [_vm._v("States Launched:")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      { staticClass: "nav nav-tabs", attrs: { role: "tablist" } },
      [
        _c("li", { staticClass: "active", attrs: { role: "presentation" } }, [
          _c(
            "a",
            {
              attrs: {
                href: "#pickl",
                "aria-controls": "pickl",
                role: "tab",
                "data-toggle": "tab"
              }
            },
            [
              _vm._v("\n          IN-STORE PICKL\n          "),
              _c("span", { staticClass: "hidden-xs" }, [_vm._v("HISTORY")])
            ]
          )
        ]),
        _c("li", { attrs: { role: "presentation" } }, [
          _c(
            "a",
            {
              attrs: {
                href: "#home-pickl",
                "aria-controls": "home-pickl",
                role: "tab",
                "data-toggle": "tab"
              }
            },
            [
              _vm._v("\n          @HOME PICKL\n          "),
              _c("span", { staticClass: "hidden-xs" }, [_vm._v("HISTORY")])
            ]
          )
        ]),
        _c("li", { attrs: { role: "presentation" } }, [
          _c(
            "a",
            {
              attrs: {
                href: "#payment",
                "aria-controls": "payment",
                role: "tab",
                "data-toggle": "tab"
              }
            },
            [
              _vm._v("\n          PAYMENT\n          "),
              _c("span", { staticClass: "hidden-xs" }, [_vm._v("HISTORY")])
            ]
          )
        ]),
        _c("li", { attrs: { role: "presentation" } }, [
          _c(
            "a",
            {
              attrs: {
                href: "#credits",
                "aria-controls": "credits",
                role: "tab",
                "data-toggle": "tab"
              }
            },
            [
              _vm._v("\n          CREDIT\n          "),
              _c("span", { staticClass: "hidden-xs" }, [_vm._v("HISTORY")])
            ]
          )
        ]),
        _c("li", { attrs: { role: "presentation" } }, [
          _c(
            "a",
            {
              attrs: {
                href: "#bonus",
                "aria-controls": "bonus",
                role: "tab",
                "data-toggle": "tab"
              }
            },
            [
              _vm._v("\n          BONUS\n          "),
              _c("span", { staticClass: "hidden-xs" }, [_vm._v("HISTORY")])
            ]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }