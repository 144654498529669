import { render, staticRenderFns } from "./AddStaffPopup.vue?vue&type=template&id=176b9e0a&scoped=true&"
import script from "./AddStaffPopup.vue?vue&type=script&lang=ts&"
export * from "./AddStaffPopup.vue?vue&type=script&lang=ts&"
import style0 from "./AddStaffPopup.vue?vue&type=style&index=0&id=176b9e0a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "176b9e0a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/naveenkumar/Sites/pickl-web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('176b9e0a')) {
      api.createRecord('176b9e0a', component.options)
    } else {
      api.reload('176b9e0a', component.options)
    }
    module.hot.accept("./AddStaffPopup.vue?vue&type=template&id=176b9e0a&scoped=true&", function () {
      api.rerender('176b9e0a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SAdmin/AddStaffPopup.vue"
export default component.exports