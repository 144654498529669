var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("h2", [_vm._v("Add Picklr To Staff")]),
            _c("button", {
              ref: "closeDactivatePopupButton",
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close"
              }
            })
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                attrs: { autocomplete: "off", "data-vv-scope": "add-staff" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.addStaff($event)
                  }
                }
              },
              [
                _c("p", [
                  _vm._v(
                    "Enter passowrd which will be required at the time of staff login."
                  )
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.staff.password,
                      expression: "staff.password"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: { required: true, min: 6 },
                      expression: "{required: true, min: 6}"
                    }
                  ],
                  class: {
                    input: true,
                    "is-danger": _vm.errors.has("add-staff.password")
                  },
                  attrs: {
                    type: "password",
                    name: "password",
                    placeholder: "Password"
                  },
                  domProps: { value: _vm.staff.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.staff, "password", $event.target.value)
                    }
                  }
                }),
                _c(
                  "button",
                  {
                    staticClass: "btn-lg-green",
                    attrs: { type: "submit", disabled: _vm.submittingForm }
                  },
                  [_vm._v("ADD TO STAFF")]
                )
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }